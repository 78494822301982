import React, { Component } from 'react';

import Input, { InputModes } from '../Input';
import Textarea from '../Textarea';
import Button from '../Button';
import Icon from '../Icon';
import { IconClose, IconCheck } from '../Icon/IconSet';

import Util from '../../common/Util';
import ServiceSite from '../../common/services/ServiceSite';

import styles from './FormContato.module.scss';

class FormContato extends Component {
    state = {
        nameValue: '',
        nameError: null,

        emailValue: '',
        emailError: null,

        phoneValue: '',
        phoneError: null,
        
        messageValue: '',
        messageError: null,

        isLoading: false,

        result: null
    };

    validateForm = async () => {
        const { nameValue, emailValue, phoneValue, messageValue } = this.state;
        let valid = true;

        if(!this.state.nameValue) {
            this.setState({ nameError: 'Informe o seu nome' });
            valid = false;
        }

        if(!this.state.emailValue) {
            this.setState({ emailError: 'Informe o seu e-mail' });
            valid = false;
        }
        else if(!Util.emailValid(this.state.emailValue)) {
            this.setState({ emailError: 'Informe um e-mail válido' });
            valid = false;
        }

        if(phoneValue.length && phoneValue.length < 15) {
            this.setState({ phoneError: 'Informe um número válido' });
            valid = false;
        }

        if(!this.state.messageValue) {
            this.setState({ messageError: 'Informe a sua mensagem' });
            valid = false;
        }

        if(!valid)
            return;

        this.setState({ isLoading: true });
        const response = await ServiceSite.SendContact({ name: nameValue, email: emailValue, phone: phoneValue, message: messageValue });
        this.setState({ isLoading: false });
        
        if(response.failure) {
            if(response.failureRequest) {
                this.setState({ result: {
                    icon: IconClose,
                    title: 'Ocorreu um erro',
                    text: response.data?.Message,
                    details: response.data?.Details?.join('; '),
                    button: 'Voltar'
                }});

                return;
            }

            this.setState({ result: {
                icon: IconClose,
                title: 'Ocorreu um erro',
                text: 'Por favor, tente novamente mais tarde.',
                button: 'Voltar'
            }});

            return;
        }

        this.setState({
            result: {
                icon: IconCheck,
                title: 'Contato enviado com sucesso!',
                text: 'Em breve a nossa equipe entrará em contato com você.',
                button: 'Continuar'
            },
            nameValue: '',
            emailValue: '',
            phoneValue: '',
            messageValue: ''
        });
    }

    render() {
        return (
            <div className={styles['container']}>

                <div className={styles['row']}>
                    <div className={styles['row-item']}>
                        <Input
                            label={'Nome'}
                            placeholder={'ex: João da Silva'}
                            value={this.state.nameValue}
                            onChange={value => this.setState({ nameValue: value })}
                            error={this.state.nameError}
                            onFocus={() => this.setState({ nameError: null })}
                            theme={'negative'}
                            disabled={this.state.isLoading}
                            onlyAlpha
                        />
                    </div>
                </div>

                <div className={styles['row']}>
                    <div className={styles['row-item']}>
                        <Input
                            label={'E-mail'}
                            placeholder={'ex: joao@gmail.com'}
                            value={this.state.emailValue}
                            onChange={value => this.setState({ emailValue: value })}
                            error={this.state.emailError}
                            onFocus={() => this.setState({ emailError: null })}
                            theme={'negative'}
                            disabled={this.state.isLoading}
                            inputMode={InputModes.email}
                            onlyLowerCase
                        />
                    </div>
                    <div className={styles['row-item']}>
                        <Input
                            label={'Celular'}
                            placeholder={'ex: (31) 99999-9999'}
                            mask={'(xx) xxxxx-xxxx'}
                            value={this.state.phoneValue}
                            onChange={value => this.setState({ phoneValue: value })}
                            error={this.state.phoneError}
                            theme={'negative'}
                            disabled={this.state.isLoading}
                            inputMode={InputModes.numeric}
                            onlyNumeric
                        />
                    </div>
                </div>

                <div className={styles['row']}>
                    <div className={styles['row-item']}>
                        <Textarea
                            label={'Mensagem'}
                            placeholder={'Escreva a sua mensagem'}
                            value={this.state.messageValue}
                            onChange={value => this.setState({ messageValue: value })}
                            error={this.state.messageError}
                            onFocus={() => this.setState({ messageError: null })}
                            theme={'negative'}
                            disabled={this.state.isLoading}
                        />
                    </div>
                </div>

                <div className={styles['button']}>
                    <Button
                        value={'Enviar'}
                        theme={'negative'}
                        onClick={this.validateForm}
                        isLoading={this.state.isLoading}
                    />
                </div>

                {!!this.state.result &&
                    <div className={styles['result']}>
                        <div className={styles['result-content']}>
                            <div className={styles['result-icon']}>
                                <Icon icon={this.state.result.icon} customSize />
                            </div>
                            <div className={styles['result-title']}>
                                {this.state.result.title}
                            </div>
                            <div className={styles['result-text']}>
                                {this.state.result.text}
                            </div>
                            {!!this.state.result.details &&
                                <div className={styles['result-details']}>
                                    {this.state.result.details}
                                </div>
                            }
                            <div className={styles['result-button']}>
                                <Button
                                    value={this.state.result.button}
                                    onClick={() => this.setState({ result: null })}
                                    theme={'negative'}
                                />
                            </div>
                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default FormContato;
