import React from 'react';
import Ink from 'react-ink';

import Icon from '../Icon';
import LoadingDots from '../LoadingDots';

import styles from './Button.module.scss';

const Button = ({ value, icon, isLoading, onClick, theme = '' }) => {
    const handleClick = ev => {
        ev.stopPropagation();
        onClick && onClick();
    }

    const containerClass = `${styles['container']} ${isLoading ? styles['is-loading'] : ''} ${theme ? styles[`theme-${theme}`] : ''}`;
    return (
        <div className={containerClass} onClick={handleClick}>
            {icon &&
                <div className={styles['icon']}>
                    <Icon icon={icon} customSize />
                </div>
            }
            {value &&
                <div className={styles['value']}>
                    {value}
                </div>
            }
            <Ink style={{ color: 'var(--color-base)' }} />
            {isLoading &&
                <div className={styles['loading']}>
                    <LoadingDots />
                </div>
            }
        </div>
    )
}

export default Button;
