const Pages = Object.freeze({
    Home: {
        Url: '/',
        Title: 'Home'
    },
    Implantes: {
        Url: '/implantes',
        Title: 'Implantes'
    },
    Estetica: {
        Url: '/estetica',
        Title: 'Estética'
    },
    Dtm: {
        Url: '/dtm',
        Title: 'DTM'
    },
    Checkup: {
        Url: '/checkup-digital',
        Title: 'Check-up digital'
    },
    Contato: {
        Url: '/contato',
        Title: 'Contato'
    }
});

export default Pages;
