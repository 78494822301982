const Constants = {
    Phone: '(31) 3286-8686',
    PhoneLink: 'tel:31 3286-8686',

    Whatsapp: '(31) 99475-2614',
    WhatsappLink: 'https://api.whatsapp.com/send?phone=5531994752614&text=Olá!%20Gostaria%20de%20tirar%20algumas%20dúvidas%20sobre%20a%20Leite%20Praça%20Odontologia',

    Email: 'contato@leitepracaodontologia.com.br',
    EmailLink: 'mailto:contato@leitepracaodontologia.com.br',

    Facebook: 'https://www.facebook.com/drcristianoleiteodontologia/',

    Instagram: 'https://www.instagram.com/dr.cristianoleitepfilho'
};

export default Constants;
