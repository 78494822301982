import ServiceBase from './ServiceBase';

import HttpMethods from '../constants/HttpMethods';

class ServiceSite extends ServiceBase {
    SendContact = async ({ name, email, phone, message }) => {
        const params = {
            method: HttpMethods.POST,
            url: '/site/contact',
            data: {
                name,
                email,
                phone,
                message
            }
        };

        return await this.makeRequest(params);
    }
}

export default new ServiceSite();
