import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider.css';

import FormContato from '../../../components/FormContato';

import styles from './Home.module.scss';

const slides = [
    {
        id: 'implantes',
        text: '<span>Segurança</span> para todos os <span>momentos da vida</span>'
    },
    {
        id: 'estetica',
        text: 'Dentes <span>brancos e lindos</span> como você <span>sempre sonhou</span>'
    },
    {
        id: 'dtm',
        text: '<span>Soluções</span> para bruxismo e <span>dores orofaciais</span>'
    },
    {
        id: 'checkup',
        text: '<span>Alta tecnologia</span> para garantir sua <span>saúde bucal</span>'
    }
];

const boxes = [
    {
        id: 'implantes',
        title: 'Implantes',
        text: 'Repor dentes perdidos através de implantes dentais tornou-se altamente acessível e previsível. A tecnologia presente nas técnicas e materiais atuais se aprimora dia após dia, possibilitando aos profissionais obter excelentes resultados.'
    },
    {
        id: 'estetica',
        title: 'Estética',
        text: 'Com uma gama cada vez maior de materiais e técnicas, ter o sorriso parecido ao das celebridades está mais próximo do que imagina. Clareamento, lentes de contato, facetas e restaurações altamente estéticas são as principais técnicas para modificar e melhorar sorrisos.'
    },
    {
        id: 'dtm',
        title: 'DTM',
        text: 'A DTM ou disfunção temporomandibular é o nome dado ao conjunto de sinais e sintomas que afeta os músculos da mastigação e/ou ATM (articulação temporomandibular).'
    },
    {
        id: 'checkup',
        title: 'Check-up digital',
        text: 'O check up digital preventivo é um exame realizado através da captura de imagens por uma câmera intra-oral de alta definição, obtendo imagens aumentadas em até 60 vezes, tornando possível a visualização de condições dentais que seriam imperceptíveis a olho nu.'
    }
];

const convenios = [
    'amil',
    'unimed'
];

class Home extends Component {
    render() {
        return (
            <div className={styles['container']}>
                
                <section className={styles['hero']}>
                    <div className={'holder'}>
                        <div className={styles['hero-slider']}>
                            <Slider
                                speed={500}
                                fade
                                autoplay
                                autoplaySpeed={4000}
                            >
                                {slides.map(({ id, text }, i) => {
                                    return (
                                        <div className={styles['hero-item-wrap']} key={i}>
                                            <div className={`${styles['hero-item']} ${styles[`hero-item-${id}`]}`}>
                                                <div className={styles['hero-item-text']} dangerouslySetInnerHTML={{ __html: text }}></div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                    </div>
                </section>

                <section className={styles['solucoes']}>
                    <div className={'holder-inner'}>
                        <div className={styles['solucoes-holder']}>
                            <div className={styles['solucoes-image']}></div>
                            <div className={styles['solucoes-main']}>
                                <div className={styles['solucoes-title']}>
                                    <h2>Soluções completas<br />para sua necessidade</h2>
                                </div>
                                <div className={styles['solucoes-subtitle']}>
                                    <h5>Nosso objetivo é transformar vidas através do sorriso, oferecendo serviços personalizados de alta qualidade.</h5>
                                </div>
                                <div className={styles['solucoes-text']}>
                                    <span className={'text'}>
                                        É indispensável ter um sorriso saudável e bonito. A odontologia atual, aliada a alta tecnologia, permite realizar tratamentos cada vez mais complexos e encontrar soluções completas e totalmente acessíveis. Sabemos que cada paciente é único e necessita de planejamentos individuais e personalizados, que permitam encontrar a solução perfeita.<br /><br />
                                        Segurança, confiança e qualidade são fundamentais para garantir a melhor experiência possível, antes, durante e após o atendimento.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={styles['boxes']}>
                    <div className={'holder-inner'}>
                        <div className={styles['boxes-viewport']}>
                            <div className={styles['boxes-holder']}>
                                {boxes.map(({ id, title, text}, i) => {
                                    return (
                                        <div className={`${styles['boxes-item']} ${styles[`boxes-item-${id}`]}`} key={i}>
                                            <div className={styles['boxes-item-image']}></div>
                                            <div className={styles['boxes-item-title']}>{title}</div>
                                            <div className={styles['boxes-item-text']}>{text}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </section>

                <div className={styles['info']}>
                    <div className={'holder-inner'}>
                        <div className={styles['info-holder']}>
                        
                            <section className={styles['convenios']}>
                                <div className={styles['convenios-title']}><h3>Convênios</h3></div>
                                <div className={styles['convenios-list']}>
                                    {convenios.map(item => <div className={`${styles['convenios-list-item']} ${styles[item]}`} key={item}></div>)}
                                </div>
                            </section>

                            <section className={styles['contato']}>
                                <div className={styles['contato-title']}><h3>Fale conosco</h3></div>
                                <div className={styles['contato-form']}>
                                    <FormContato />
                                </div>
                            </section>

                        </div>
                    </div>
                </div>

                <section className={styles['social']}>
                    <div className={'holder-inner'}>
                        <div className={styles['social-holder']}>

                            <div className={styles['social-doctoralia']}>
                                <Helmet>
                                    <script src={'/js/doctoralia.js'}></script>
                                </Helmet>
                                <a
                                    id='zl-url'
                                    className='zl-url'
                                    href='https://www.doctoralia.com.br/cristiano-leite-praca-filho/dentista/belo-horizonte'
                                    rel='nofollow'
                                    data-zlw-doctor='cristiano-leite-praca-filho'
                                    data-zlw-type='big'
                                    data-zlw-opinion='false'
                                    data-zlw-hide-branding='true'>
                                    Cristiano Leite Praça Filho - Doctoralia.com.br
                                </a>
                            </div>

                            <div className={styles['social-facebook']}>
                                <div className="fb-page" data-href="https://facebook.com/drcristianoleiteodontologia" data-tabs="" data-width="500" data-height="300" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://facebook.com/drcristianoleiteodontologia" className="fb-xfbml-parse-ignore"><a href="https://facebook.com/drcristianoleiteodontologia">Dr. Cristiano Leite Odontologia</a></blockquote></div>
                            </div>

                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default Home;
