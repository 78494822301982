import Actions from './SiteActionsConstants';

const initialState = {
    isMobile: true
};

const SiteReducer = (state = initialState, { type, payload }) => {
    switch(type) {
        case Actions.SET_MOBILE:
            return { ...state, isMobile: payload };
        
        default:
            return state;
    }
}

export default SiteReducer;
