import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Ink from 'react-ink';

import Icon from '../Icon';
import { IconMenu, IconClose, IconPhone, IconWhatsapp } from '../Icon/IconSet';
import Slip from '../Slip';
import Button from '../Button';

import Pages from '../../common/Pages';
import Util from '../../common/Util';
import Constants from '../../common/Constants';

import history from '../../infrastructure/history';

import styles from './Header.module.scss';

const menu = [
    { Label: 'Home', Target: Pages.Home.Url },
    { Label: 'Implantes', Target: Pages.Implantes.Url },
    { Label: 'Estética', Target: Pages.Estetica.Url },
    { Label: 'DTM', Target: Pages.Dtm.Url },
    { Label: 'Check-up digital', Target: Pages.Checkup.Url },
    { Label: 'Contato', Target: Pages.Contato.Url },
]

const Header = () => {
    const [current, setCurrent] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        handleCurrent();
        history.listen(handleCurrent);
    }, []);

    const handleCurrent = () => setCurrent(history.location.pathname);

    return (
        <>
        <div className={styles['container']}>
            <div className={'holder'}>
                <div className={styles['content']}>

                    <div className={styles['logo']} onClick={() => { Util.redirect(Pages.Home.Url); Util.scrollTo(0, false); }}></div>

                    <div className={styles['desktop']}>
                        <nav className={styles['menu']}>
                            <ul>
                                {menu.map(({ Label, Target }, i) => {
                                    const itemClass = `${styles['menu-item']} ${current === Target ? styles['current'] : ''}`;
                                    return (
                                        <li key={i}>
                                            <Link to={Target}>
                                                <div className={itemClass}>
                                                    {Label}
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </nav>
                    </div>

                    <div className={styles['mobile']}>
                        <div className={styles['drawer-button']} onClick={() => setDrawerOpen(true)}>
                            <Icon icon={IconMenu} customSize />
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div className={styles['header-fix']}></div>

        <Slip isOpen={drawerOpen} closeCallback={() => setDrawerOpen(false)}>
            <div className={styles['drawer']}>
                <div className={styles['drawer-top']}>
                    <div className={styles['drawer-close']} onClick={() => setDrawerOpen(false)}>
                        <Icon icon={IconClose} customSize />
                    </div>
                </div>
                <div className={styles['drawer-buttons']}>
                    <div className={styles['drawer-buttons-item']}>
                        <Button value={Constants.Phone} icon={IconPhone} onClick={() => Util.redirect(Constants.PhoneLink)} />
                    </div>
                    <div className={styles['drawer-buttons-item']}>
                        <Button value={Constants.Whatsapp} icon={IconWhatsapp} onClick={() => Util.redirect(Constants.WhatsappLink)} />
                    </div>
                </div>
                <nav className={styles['drawer-menu']}>
                    <ul>
                        {menu.map(({ Label, Target }, i) => {
                            return (
                                <li key={i}>
                                    <Link to={Target}>
                                        <div className={styles['drawer-menu-item']} onClick={() => { setDrawerOpen(false); Util.scrollTo(0, false); }}>
                                            {Label}
                                            <Ink />
                                        </div>
                                    </Link>
                                </li>            
                            )
                        })}
                    </ul>
                </nav>
            </div>
        </Slip>

        </>
    )
}

export default Header;
