import React, { useState, useRef } from 'react';

import styles from './Textarea.module.scss';

const Textarea = ({ label, placeholder, value, onChange, onFocus, onBlur, theme, error, disabled }) => {
    const [hasFocus, setHasFocus] = useState(false);

    const inputRef = useRef();

    const handleOnFocus = () => {
        if(disabled) {
            inputRef.current.blur();
            return;
        }
        setHasFocus(true);
        onFocus && onFocus();
    }

    const handleOnBlur = () => {
        setHasFocus(false);
        onBlur && onBlur();
    }

    const containerClass = `${styles['container']} ${hasFocus ? styles['has-focus'] : ''} ${!!value?.length ? styles['has-value'] : ''} ${theme ? styles[`theme-${theme}`] : ''} ${!!error?.length ? styles['has-error'] : ''} ${disabled ? styles['disabled'] : ''}`;
    return (
        <div className={containerClass} onClick={() => inputRef?.current?.focus()}>
            <div className={styles['content']}>
                <div className={styles['label']}>{label}</div>
                <textarea
                    ref={inputRef}
                    className={styles['input']}
                    value={value}
                    onChange={ev => onChange && onChange(ev.target.value)}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    placeholder={placeholder}
                />
            </div>
            <div className={styles['error']}>{error}</div>
        </div>
    )
}

export default Textarea;
