import React, { useRef } from 'react';

import usePrevious from '../../common/hooks/usePrevious';

import styles from './Slip.module.scss';

const Slip = ({ isOpen, closeCallback, children }) => {
    const backdropRef = useRef();
    const prevIsOpen = usePrevious(isOpen);

    const isClosing = !isOpen && prevIsOpen;

    if(isClosing) {
        setTimeout(() => {
            backdropRef.current && backdropRef.current.classList.remove(styles['is-closing']);
        }, 250);
    }
    
    const backdropClass = `${styles['backdrop']} ${isOpen ? styles['open'] : ''} ${isClosing ? styles['is-closing'] : ''}`;
    return (
        <div className={backdropClass} onClick={() => closeCallback()} ref={backdropRef}>
            <div className={styles['container']} onClick={ev => ev.stopPropagation()}>
                <div className={styles['content']}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Slip;
