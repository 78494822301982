import Actions from './SiteActionsConstants';

const SiteActions = {
    setMobile: isMobile => ({
        type: Actions.SET_MOBILE,
        payload: isMobile
    })
}

export default SiteActions;
