import history from '../infrastructure/history';

const Util = {
    redirect: (path, newTab = false) => {
        if(typeof path !== 'string') {
            console.error('Util.redirect: path must be a string');
            return;
        }
        
        if (path.includes('http') || path.includes('tel:') || path.includes('mailto:') || newTab) {
            const a = document.createElement('a');
            a.setAttribute('href', path);
            a.setAttribute('rel', 'noopener noreferrer');
            a.setAttribute('target', '_blank');
            a.click();
            return;
        }

        history.push(path);
    },

    emailValid: email => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },

    applyMask: (mask, value) => {
        if(!mask)
            return value;
        
        if(!value?.length)
            return '';

        let _value = value.replace(/[^a-zA-Z0-9]/gi, '').split('');
        let _mask = mask.split('');
        for(let i = 0; ; i++) {
            if(i > mask.length)
                break;

            if(_mask[i] !== 'x') {
                continue;
            }
            else{
                _mask[i] = _value[0];
                _value.splice(0, 1);

                if(_value.length === 0) {
                    const increment = _mask[i + 1] !== 'x' && value.length === i + 2 ? i + 2 : i + 1;
                    _mask.splice(increment, _mask.length);
                    break;
                }
            }
        }
        return _mask.join('');
    },

    isMobile: () => {
        return window.innerWidth < 768;
    },

    isNumeric: (str, ignore, mask) => {
        if(str === null || str === undefined)
            return false;

        const _ignore = ignore || [];
        const _mask = mask || '';

        const _ignoreFormatted = _ignore.join();
        const _maskFormatted = [ ...new Set(_mask.replace(/x/gi, '')) ].join().replace('/', '\\/');
        
        const regex = new RegExp(`^[0-9${_ignoreFormatted}${_maskFormatted}]+`);
        return str.replace(regex, '').length === 0;
    },

    isAlpha: (str, ignore = []) => {
        let _ignore = [' ', 'á', 'é', 'í', 'ó', 'ú', 'Á', 'É', 'Í', 'Ó', 'Ú', 'ç', 'Ç', 'ã', 'õ', 'Ã', 'Õ', '´', '˜'];
        _ignore = [ ...ignore, ..._ignore ];
        _ignore = _ignore.join('').replace(/[.*+?^${}()\-|[\]\\]/g, '\\$&');
        const regex = new RegExp(`[^a-zA-Z${_ignore}]`, 'gi');
        const _str = str.replace(regex, '');
        return str.length === _str.length;
    },

    scrollTo: (height, smooth = true) => {
        window.scroll({ top: height, behavior: smooth ? 'smooth' : 'auto' });
    }
}

export default Util;
