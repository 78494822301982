export const EnvironmentTypes = {
    Local: 1,
    Prd: 2
};

const Environment = {
    getCurrent: () => {
        const hostname = window?.location?.hostname;
        const { Local, Prd } = EnvironmentTypes;

        const environments = {
            'localhost': Local,
            'leitepracaodontologia': Prd
        };

        for(const [url, env] of Object.entries(environments))
            if(hostname.includes(url))
                return env;
        
        return Local;
    },

    apiUrls: {
        Local: 'http://localhost:30000',
        Prd: 'https://api.server.vbases.com/leite-praca'
    },

    ApiUrl: () => {
        const current = Environment.getCurrent();
        const { Local, Prd } = EnvironmentTypes;
        const { apiUrls } = Environment;

        switch(current) {
            case Local:
                return apiUrls.Local;
            
            case Prd:
                return apiUrls.Prd;
            
            default:
                return apiUrls.Local;
        }
    }
}

export default Environment;
