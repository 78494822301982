import React, { Component, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import AppLoading from '../components/AppLoading';
import WhatsappButton from '../components/WhatsappButton';

import Pages from '../common/Pages';
import Util from '../common/Util';

import styles from './App.module.scss';

import Home from './pages/Home';
const Implantes = React.lazy(() => import('./pages/Implantes'));
const Estetica = React.lazy(() => import('./pages/Estetica'));
const Dtm = React.lazy(() => import('./pages/Dtm'));
const Checkup = React.lazy(() => import('./pages/Checkup'));
const Contato = React.lazy(() => import('./pages/Contato'));
const Page404 = React.lazy(() => import('./pages/Page404'));

class App extends Component {
    componentDidMount() {
        const { setMobile } = this.props;

        setMobile(Util.isMobile());
        window.addEventListener('resize', () => setMobile(Util.isMobile()));
    }

    render() {
        const { history } = this.props;

        return (
            <Router history={history}>
                <div className={styles['container']}>

                    <Header />

                    <div className={styles['content']}>
                        <Suspense fallback={<AppLoading />}>
                            <Switch>
                                <Route path={Pages.Home.Url} exact render={() => <Home />} />
                                <Route path={Pages.Implantes.Url} render={() => <Implantes />} />
                                <Route path={Pages.Estetica.Url} render={() => <Estetica />} />
                                <Route path={Pages.Dtm.Url} render={() => <Dtm />} />
                                <Route path={Pages.Checkup.Url} render={() => <Checkup />} />
                                <Route path={Pages.Contato.Url} render={() => <Contato />} />
                                <Route render={() => <Page404 />} />
                            </Switch>
                        </Suspense>
                    </div>

                    <Footer />
                    <WhatsappButton />

                </div>
            </Router>
        )
    }
}

export default App;
