import axios from 'axios';

import Environment from '../../infrastructure/Environment';
import HttpStatus from '../constants/HttpStatus';

class ServiceBase {
    headers = {};
    baseUrl = Environment.ApiUrl();
    
    response = {
        failure: false,
        failureRequest: false,
        failureServer: false,
        statusCode: null,
        data: null
    };

    makeRequest = async params => {
        if(params.formUrlEncoded)
            params.data = Object.keys(params.data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params.data[k])}`).join('&');
        
        try {
            const response = await axios({
                method: params.method,
                url: params.url,
                baseURL: this.baseUrl,
                headers: this.headers,
                data: params.data || null,
                responseType: params.responseType || 'json',
                responseEncoding: 'utf8',
            }, { crossdomain: true });

            return this._handleSuccess(response);
        }
        catch(err) {
            return this._handleError(err);
        }
    }

    _handleSuccess = response => {
        const _response = { ...this.response };

        const statusDescription = this._getStatusDescription(response.status);

        _response.data = response.data?.Data;
        _response.statusCode = response.status;

        if(statusDescription)
            _response[statusDescription] = true;
        
        return _response;
    }

    _handleError = err => {
        const _response = { ...this.response };

        _response.failure = true;
        _response.failureServer = true;

        if(err && err.response) {
            const statusCode = err.response.status;
            const statusDescription = this._getStatusDescription(statusCode);

            _response.statusCode = statusCode;
            _response.data = err.response.data;
            _response.failureRequest = statusCode >= 400 && statusCode < 500;
            _response.failureServer = statusCode > 500;

            if(statusDescription)
                _response[statusDescription] = true;
        }

        return _response;
    }

    _getStatusDescription = status => {
        let _status = Object.keys(HttpStatus).find(key => HttpStatus[key] === status);
        
        if(!_status)
            return status;
        
        return `${_status.charAt(0).toLowerCase()}${_status.slice(1)}`;
    }
}

export default ServiceBase;
