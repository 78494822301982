import React from 'react';

import Icon from '../Icon';
import { IconWhatsapp, IconFacebook, IconInstagram } from '../Icon/IconSet';

import Constants from '../../common/Constants';
import Util from '../../common/Util';

import styles from './Footer.module.scss';

const social = [
    { id: 'whatsapp', icon: IconWhatsapp, target: Constants.WhatsappLink },
    { id: 'facebook', icon: IconFacebook, target: Constants.Facebook },
    { id: 'instagram', icon: IconInstagram, target: Constants.Instagram }
];

const Footer = () => {
    return (
        <>
        <div className={styles['container']}>
            <div className={'holder'}>

                <div className={styles['top']}>
                    <div className={styles['social']}>
                        {social.map(({ icon, target }, i) => {
                            return (
                                <div className={styles['social-item-wrap']} key={i}>
                                    <div className={styles['social-item']} onClick={() => Util.redirect(target)}>
                                        <Icon icon={icon} customSize />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className={styles['split']}></div>

                <div className={styles['content']}>

                    <div className={styles['logo']}></div>

                    <div className={styles['phone']}>
                        <span onClick={() => Util.redirect(Constants.PhoneLink)}>{Constants.Phone}</span>
                    </div>

                    <div className={styles['address']}>
                        Rua Turquesa, 45<br />
                        Prado - Belo Horizonte/MG<br />
                        CEP 30411-177
                    </div>

                </div>

            </div>
        </div>

        <div className={styles['bottom']}>
            <div className={'holder'}>
                <div className={styles['bottom-holder']}>

                    <div className={styles['copyright']}>
                        © 2023 - Direitos reservados
                    </div>

                    <div className={styles['vbases']}>
                        <a href={'https://vbases.com'} rel={'noopener noreferrer'} target={'_blank'}>
                            <div className={styles['vbases-logo']}></div>
                        </a>
                    </div>

                </div>
            </div>
        </div>
        </>
    )
}

export default Footer;
