import React, { useState, useRef } from 'react';

import Util from '../../common/Util';

import styles from './Input.module.scss';

export const InputModes = {
    text: 'text',
    decimal: 'decimal',
    numeric: 'numeric',
    tel: 'tel',
    search: 'search',
    email: 'email',
    url: 'url'
};

const Input = ({ label, placeholder, value, onChange, disabled, onFocus, onBlur, theme, error, inputMode = InputModes.Text, onlyNumeric = false, onlyAlpha = false, mask, onlyLowerCase = false, onlyUpperCase = false }) => {
    const [hasFocus, setHasFocus] = useState(false);

    const inputRef = useRef();

    const handleOnChange = ev => {
        const { value } = ev.target;

        if(onlyNumeric && !Util.isNumeric(value, null, mask))
            return;
        else if(onlyAlpha && !Util.isAlpha(value))
            return;
        
        onChange && onChange(value);
    }

    const handleFocus = () => {
        if(disabled) {
            inputRef.current.blur();
            return;
        }
        setHasFocus(true);
        onFocus && onFocus();
    }

    const handleBlur = () => {
        setHasFocus(false);
        onBlur && onBlur();
    }

    const _inputMode = InputModes[inputMode] ?? InputModes.Text;
    const containerClass = `${styles['container']} ${hasFocus ? styles['has-focus'] : ''} ${!!value?.length ? styles['has-value'] : ''} ${theme ? styles[`theme-${theme}`] : ''} ${!!error ? styles['has-error'] : ''} ${disabled ? styles['disabled'] : ''} ${onlyLowerCase ? styles['only-lowercase'] : ''} ${onlyUpperCase ? styles['only-uppercase'] : ''}`;
    return (
        <div className={containerClass} onClick={() => inputRef?.current?.focus()}>
            <div className={styles['content']}>
                <div className={styles['label']}>{label}</div>
                <input
                    ref={inputRef}
                    className={styles['input']}
                    type={'text'}
                    value={Util.applyMask(mask, value)}
                    onChange={handleOnChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder={placeholder}
                    inputMode={_inputMode}
                />
            </div>
            <div className={styles['error']}>{error}</div>
        </div>
    )
}

export default Input;
